@import "./custom-search-bar.css";
@import "./custom-message.css";
@import "./custom-chat-item.css";
@import "./custom-channel-header.css";
@import "./chat-avatar.css";

/*.custom-font-emoji {*/
/*    font-family: 'Noto Emoji', 'Apple Color Emoji', 'Segoe UI Emoji', 'EmojiOne Color', sans-serif;*/
/*}*/

.custom-font-emoji {
    font-family: 'Apple Color Emoji', 'Noto Emoji', 'Segoe UI Emoji', 'Figtree', sans-serif;
}

/*OVERWRITTEN GETSTREAM CLASSES*/

.str-chat .str-chat__container {
    @apply bg-gray-light
}

.str-chat__list {
    @apply bg-transparent px-4 md:px-0
}

.str-chat__list .str-chat__message-list-scroll {
    @apply px-0
}

.str-chat__list .str-chat__ul {
    @apply space-y-3
}

.owner .str-chat__message-options {
    @apply flex-row
}

.str-chat__message-options > * {
    @apply flex items-center justify-center
}

.str-chat__channel-list-react .str-chat__channel-list-messenger-react .str-chat__channel-list-messenger-react__main {
    @apply space-y-0.5
}

.str-chat__modal--open .str-chat__modal__inner {
    width: fit-content;
}

/*CHAT IMAGES ATTACHMENTS*/

.str-chat__base-image,
.str-chat__gallery-image{
    cursor: zoom-in;
    pointer-events: none;
}

.str-chat__attachment-list .str-chat__message-attachment--image:not(.str-chat__message-attachment--card) {
    margin: 0;
}

.str-chat__attachment-list .str-chat__message-attachment--image:not(.str-chat__message-attachment--card) > img {
    width: auto !important;
    height: auto !important;
    max-width: 100% !important;

    object-fit: cover;
    display: block;
}

.str-chat__base-image.landscape {
    max-width: 500px;
    max-height: 235px;
}

.str-chat__base-image.portrait {
    max-height: 450px;
    max-width: 276px;
}

.str-chat__channel-search.str-chat__channel-search--with-results.str-chat__channel-search--inline {
    height: fit-content;
}

.str-chat__main-panel-inner {
    overflow: hidden;
}

.str-chat__attachment-list .str-chat__message-attachment--gallery .str-chat__gallery.str-chat__gallery-two-rows {
    height: 400px;
}

.str-chat__attachment-list .str-chat__message-attachment--gallery .str-chat__gallery {
    height: 200px;
}

/*REACTIONS - EMOJI - COUNTER EMOJIS*/

.custom-message-wrapper.owner .str-chat__message-reactions-container .str-chat__message-reactions .str-chat__message-reaction {
    @apply rounded-2xl bg-gray-light w-8 h-8 font-semibold text-black pointer-events-none
}

.custom-message-wrapper.owner .str-chat__message-reactions-container .str-chat__message-reactions button {
    @apply flex justify-center items-center
}

.custom-message-wrapper.opponent .str-chat__message-reactions-container .str-chat__message-reactions .str-chat__message-reaction {
    @apply rounded-2xl bg-white w-8 h-8 font-semibold text-black shadow-reaction relative pointer-events-none
}

.str-chat__message-reactions-container .str-chat__message-reactions .str-chat__message-reaction .str-chat__message-reaction-emoji {
    @apply relative left-[1px]
}

.str-chat__message-reactions-container .str-chat__message-reactions .str-chat__message-reaction .str-chat__message-reaction-count {
    @apply hidden
}

.str-chat__message-reactions-container .str-chat__message-reactions .str-chat__message-reaction span {
    @apply custom-font-emoji text-lg;
}

.str-chat__list > div,
.str-chat__list .str-chat__ul {
    line-height: 0 !important;
    font-size: 0 !important;
    position: relative !important;
    margin: 0 !important;
    padding: 0 !important;
    height: 100%;
}

.str-chat__list .str-chat__ul {
    height: fit-content!important;
}

.str-chat__attachment-list .str-chat__message-attachment-card .str-chat__message-attachment-card--content {
    @apply hidden;
}
