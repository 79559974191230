.chat-avatar-wrapper{
    @apply rounded-2xl overflow-clip
}

.chat-avatar-wrapper.active-chat{
    @apply border-2 border-primary
}

.chat-avatar-md{
    @apply min-w-[48px] w-[48px] max-w-[48px] h-[48px]
}

.chat-avatar-lg{
    @apply min-w-[72px] w-[72px] max-w-[72px] h-[72px]
}

.chat-avatar-wrapper img {
    @apply object-cover rounded-2xl object-center h-full w-full
}

.chat-avatar-wrapper.active-chat img {
    @apply scale-110
}

.chat-avatar-wrapper .placeholder-img {
    @apply w-full h-full rounded-2xl object-cover overflow-hidden;
}

.chat-avatar-wrapper.active-chat .placeholder-img {
    @apply scale-110;
}

.chat-avatar-wrapper .user-indicator{
    @apply absolute rounded-full top-[0.3rem] -right-[0.3rem] w-2 h-2 bg-semantic-success border-2 border-white box-content
}
