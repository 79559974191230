.coin {
    width: 1em;
    height: 1em;
    background: var(--secondary);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.coin-inner {
    width: 0.8em;
    height: 0.8em;
    background: #FEC535;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.coin-text {
    position: relative;
    color: var(--secondary);
    font-weight: bold;
    text-shadow: 0.7px 0.7px #FCB500;
}

.y{
    position: relative;
    top:10%;
    left:7%;
    font-size: 0.4em;
}

.c{
    position: relative;
    top:-2%;
    left:-6%;
    font-size: 0.5em;
}
