@font-face {
    font-family: 'Figtree';
    src: url('/src/assets/fonts/Figtree-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Figtree';
    src: url('/src/assets/fonts/Figtree-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Figtree';
    src: url('/src/assets/fonts/Figtree-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Figtree';
    src: url('/src/assets/fonts/Figtree-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Figtree';
    src: url('/src/assets/fonts/Figtree-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Figtree';
    src: url('/src/assets/fonts/Figtree-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Figtree';
    src: url('/src/assets/fonts/Figtree-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Figtree';
    src: url('/src/assets/fonts/Figtree-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Figtree';
    src: url('/src/assets/fonts/Figtree-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Figtree';
    src: url('/src/assets/fonts/Figtree-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Figtree';
    src: url('/src/assets/fonts/Figtree-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Figtree';
    src: url('/src/assets/fonts/Figtree-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Noto Emoji';
    src: url('/src/assets/fonts/NotoColorEmoji-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
