.custom-chat-item-wrapper {
    @apply flex items-center p-2 hover:bg-gray-light focus:bg-gray-light cursor-pointer rounded-2xl;
}

.custom-chat-item-wrapper.active-chat {
    @apply !bg-gray-light;
}

.custom-chat-item-details {
    @apply flex flex-col ml-3 overflow-x-hidden w-full;
}

.custom-chat-item-details > .header {
    @apply flex justify-between items-center gap-1;
}

.custom-chat-item-details > .header .subscriber {
    @apply bg-[#FFDE88] min-w-[20px] p-1 rounded-full h-[20px] text-black
}

.custom-chat-item-details > .header .subscriber svg {
    @apply h-[11px] px-[2px] w-fit
}

.custom-chat-item-details > .header .name {
    @apply font-semibold text-base leading-[1.2rem] text-black max-w-[228px] truncate;
}

.custom-chat-item-details > .header .last-active,
.custom-chat-item-extra .last-active{
    @apply flex gap-2 items-center font-medium text-xs text-special-gray leading-[0.9rem] min-w-fit text-nowrap;
}

.custom-chat-item-extra {
    @apply flex justify-between items-center gap-1 overflow-hidden min-h-[24px];
}

.custom-chat-item-extra .last-message {
    @apply font-medium text-sm leading-[1.05rem] text-special-gray max-w-[228px] truncate;
}
