/* Chrome, Safari, Edge, Opera */
.disable-arrows-input-number[type=number]::-webkit-outer-spin-button,
.disable-arrows-input-number[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.disable-arrows-input-number[type=number] {
    -moz-appearance: textfield;
}
