.spinner-loader {
    width: 1em; /* Dynamiczny rozmiar zależny od font-size rodzica */
    height: 1em; /* Dynamiczny rozmiar zależny od font-size rodzica */
    border: 0.1em solid currentColor; /* Dziedziczenie koloru z rodzica */
    border-bottom-color: transparent; /* Tworzy efekt smugi */
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
