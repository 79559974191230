.custom-search-bar-wrapper {
    @apply
    relative flex items-center w-full max-w-lg px-2
}

.custom-search-bar-icon {
    @apply
    absolute left-5 text-special-gray w-[0.875rem] h-[0.875rem]
}

.custom-search-bar-input {
    @apply
    w-full rounded-2xl pl-9 p-3 bg-gray-light focus:outline-none text-[0.875rem]
    font-medium leading-4 placeholder:text-[#7A7A7A] text-[#7A7A7A] max-h-[40px] h-[40px]
}
