/* Wrapper główny */
.custom-channel-header-wrapper {
    @apply flex items-center justify-between p-2 rounded-t-none md:rounded-t-3xl rounded-3xl bg-white flex-wrap gap-3;
    @apply z-20
}

/* Sekcja lewa: avatar, nazwa użytkownika, przycisk powrotu */
.custom-channel-header-left {
    @apply flex w-fit md:w-fit justify-between items-center;
}

/* Ikona powrotu (tylko na mobile) */
.custom-channel-header-back-button {
    @apply flex md:hidden w-4 h-4 items-center justify-center rounded-lg p-3 mr-2 cursor-pointer
    hover:shadow-[0_0_0_1px_rgba(128,128,128,0.5)] focus:shadow-[0_0_0_1px_rgba(128,128,128,0.5)];
}

.custom-channel-header-chevron-icon {
    @apply text-special-gray min-w-2 w-2 h-3;
}

/* Avatar użytkownika */
.custom-channel-header-avatar {
    @apply h-[48px] w-[48px] md:h-[72px] md:w-[72px] rounded-2xl object-cover bg-primary-pastel p-[2px] mr-4;
}

/* Avatar domyślny */
.custom-channel-header-avatar-default {
    @apply h-[48px] w-[48px] md:h-[72px] md:w-[72px] p-[2px] mr-4 rounded-2xl object-cover overflow-hidden;
}

/* Sekcja z nazwą użytkownika i statusem */
.custom-channel-header-user-info {
    @apply flex flex-col gap-y-2;
}

.custom-channel-header-user-name {
    @apply text-xl font-bold leading-[1.5rem];
}

.custom-channel-header-last-seen {
    @apply text-special-gray text-xs leading-[0.9rem];
}

/* Sekcja prawa: przyciski (voice, video, options) */
.custom-channel-header-buttons {
    @apply md:flex md:items-center md:space-x-2 md:w-full md:w-fit md:ml-auto;
}
