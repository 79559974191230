@import "input-number.css";
@import "input-date-picker.css";
@import "animations.css";
@import "input-text-area.css";
@import "shadows.css";
@import "cmp.css";

input:focus {
    border-color: transparent!important;
}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

.show-scrollbar::-webkit-scrollbar {
    display: block;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
