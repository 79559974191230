.custom-message-wrapper {
    @apply flex flex-col w-full max-w-full gap-1
}

.custom-message-wrapper.owner {
    @apply items-end
}

.custom-message-wrapper.opponent {
    @apply items-start
}

.custom-message-wrapper.owner .quoted-message{
    @apply justify-end;
}

.custom-message-wrapper.opponent .quoted-message{
    @apply justify-start;
}

.custom-message-wrapper .card {
    @apply !p-0 !gap-[2px] !min-w-[0] !border-0
}

.custom-message-wrapper .card.selected-message {
    @apply z-[2]
}

.custom-message-wrapper .message-text p{
    word-break: break-word;
    hyphens: auto;
    @apply whitespace-pre-line
}

.custom-message-wrapper .quoted-message p{
    @apply truncate;
}

.dp-message{
    @apply flex flex-wrap gap-[4px] justify-end w-full;
}

.dp-message-action-info{
    @apply text-special-gray font-medium text-xs leading-[0.9rem] flex gap-1 items-center w-full
}

.dp-message-action-info > .dp-message-icon{
    @apply w-3 h-3 min-w-3
}

.highlighted-message .custom-message-wrapper .card::before {
    content: "";
    position: absolute;
    inset: 0;
    pointer-events: none;
    background-color: rgba(0,0,0,0.15);
    animation: fadeHighlight 0.75s ease-out forwards;
    @apply rounded-3xl;
}

@keyframes fadeHighlight {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
