:root {

    --primary: #5E17EB;
    --primary-darken: #2E0B73;
    --primary-pastel: #EBE5FF;
    --secondary: #FFDE88;
    --secondary-pastel: #FFDE8826;

    --semantic-success: #14D16C;
    --semantic-success-pastel: #14D16C12;

    --semantic-info: #4793ff;
    --semantic-info-pastel: #4793ff12;

    --semantic-warning: #FF6536;
    --semantic-warning-pastel: #FF653612;

    --semantic-danger: #F03933;
    --semantic-danger-pastel: #F0393312;


    --gray: #6E6F70;
    --gray-light: #F5F7F9;
    --black: #202121;

    --gray-border: #E1E3E5;

}
