/* Wysuwanie z dołu */
@keyframes fade-slide-up {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.fade-slide-up {
    animation: fade-slide-up 0.5s 0.3s ease-out forwards;
}

/* Wysuwanie z góry */
@keyframes fade-slide-down {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.fade-slide-down {
    animation: fade-slide-down 0.5s ease-out forwards;
}

/* Wysuwanie z lewej */
@keyframes fade-slide-left {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.fade-slide-left {
    animation: fade-slide-left 0.5s ease-out forwards;
}

/* Wysuwanie z prawej */
@keyframes fade-slide-right {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.fade-slide-right {
    animation: fade-slide-right 0.5s ease-out forwards;
}
