.cmpboxrecall {
    position: fixed;
    bottom: unset!important;
    left: unset!important;
    right: 10px;
    top: 10px
}

A.cmpboxrecalllink, A.cmpboxrecalllink:active, A.cmpboxrecalllink:focus, A.cmpboxrecalllink:hover, A.cmpboxrecalllink:link, A.cmpboxrecalllink:visited {
    @apply !flex flex-row-reverse items-center
}
